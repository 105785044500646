import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import PageTransitionContainer from "../components/PageTransitionContainer"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import "../styles/pages/faq.css"
const ReactMarkdown = require("react-markdown")

const FAQPageTemplate = ({ faq }) => {
  let { heading, questions } = faq
  return (
    <div id="faq" className="container">
      <h1 className="page-heading">{heading}</h1>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {questions &&
          questions.length > 0 &&
          questions.map((q, id) => (
            <AccordionItem key={id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h3 style={{ display: "inline" }}>{q.question}</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ReactMarkdown>{q.answer}</ReactMarkdown>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
      <p>
        Still have more questions or want to speak to an advisor? Give us a call
        at <a href="tel:18888779851">1-888-877-9851</a> or send us a message
        from our <Link href="/contact">contact page</Link>
      </p>
    </div>
  )
}

FAQPageTemplate.propTypes = {
  faq: PropTypes.shape({
    heading: PropTypes.string,
    questions: PropTypes.array
  })
}

const FAQPage = ({ data, location }) => {
  return (
    <PageTransitionContainer>
      <Layout location={location} page="faq">
        <Seo
          title="RateLock | Frequently Asked Questions | FAQ"
          location={location}
        />
        <FAQPageTemplate
          faq={
            data && data.faqs && data.faqs.frontmatter
              ? data.faqs.frontmatter
              : null
          }
        />
      </Layout>
    </PageTransitionContainer>
  )
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default FAQPage

export const pageQuery = graphql`
  query faqPageQuery {
    faqs: markdownRemark(frontmatter: { templateKey: { eq: "faq-page" } }) {
      frontmatter {
        title
        heading
        questions {
          question
          answer
        }
      }
    }
  }
`
